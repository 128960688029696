import Vue from 'vue'
import VueRouter from 'vue-router'
import Finder from '@/views/Finder.vue'
import DirectPath from '@/views/DirectPath.vue'
// import NotFound from '@/views/NotFound.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Finder',
    component: Finder
  },
  {
    path: '/:serialNumber/:macAddress',
    name: 'DirectPath',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/DirectPath.vue')
    component: DirectPath,
    props: true
  },
  {
    path: '/my_cube/:serialNumber/:macAddress',
    name: 'DirectPathWithPrefix',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/DirectPath.vue')
    component: DirectPath,
    props: true
  },
  {
    // In history mode we need a fallback path since there is no server 404
    path: '*', 
    name: 'NotFound',
    // component: NotFound
    component: Finder
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
