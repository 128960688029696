<template>
  <div class="wrapper-fh">
    <div
      v-if="!unitinfo"
      class="container py-4"
    >
      <h1 class="text-center mb-2">Find my CUBE</h1>

      <form>
        <label
          class="h5 text-center"
          for="serialNumber"
        >Serial Number</label>
        <input
          class="form-control"
          id="serialNumber"
          name="serialNumber"
          placeholder="35mx..."
          type="text"
          v-model="serialNumber"
        >
        <label
          class="h5 text-center"
          for="macAddress"
        >Mac Address</label>
        <input
          class="form-control"
          id="macAddress"
          name="macAddress"
          placeholder="001beb..."
          type="text"
          v-model="macAddress"
        >
        <button
          class="button"
          @click.prevent="findCube"
        >Find</button>
      </form>
      <div class="my-4">
        <a
          href="https://support.cubemc.com/portal/en/kb/articles/cube-installation-guide"
          target="_blank"
        >View the CUBE Installation Guide</a>
      </div>
    </div>
    <Unit v-if="unitinfo" :unitinfo="unitinfo" :serialNumber="serialNumber" :macAddress="macAddress"/>
  </div>
</template>

<script>
import Unit from '@/components/Unit.vue'

export default {
  name: 'Finder',
  components: {
    Unit
  },
  data () {
    return {
      serialNumber: '',
      macAddress: '',
      unitinfo: null
    }
  },
  methods: {
    async findCube () {
      try {
        const response = await this.axios.get("/activation/unit", {
          params: {
            serialNumber: this.serialNumber,
            macAddress: this.macAddress
          }
        })
        if ('network' in response.data ) {
          this.unitinfo = response.data
          return
        }
      } catch (e) {
        console.log(e)
      }
      this.serialNumber = ''
      this.macAddress = ''
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
