<template>
  <div class="wrapper-fh">
    <h2
      v-if="!unitinfo"
      class="h3 text-center py-1rem"
    >
      Checking CUBE...
    </h2>
    <Unit
      v-if="unitinfo"
      :unitinfo="unitinfo"
      :serialNumber="serialNumber"
      :macAddress="macAddress"
    />

  </div>
</template>

<script>
import Unit from '@/components/Unit.vue'

export default {
  name: 'DirectPath',
  props: ['serialNumber', 'macAddress'],
  components: {
    Unit
  },
  data () {
    return {
      unitinfo: null
    }
  },
  async mounted () {
    try {
      const response = await this.axios.get("/activation/unit", {
        params: {
          serialNumber: this.serialNumber,
          macAddress: this.macAddress
        }
      })
      if ('network' in response.data) {
        this.unitinfo = response.data
        return
      } else {
        this.$router.push('/')
      }
    } catch (e) {
      console.log(e)
      this.$router.push('/')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>