<template>
  <div id="app">

    <nav class="nav-wrapper sfp-nav">
      <div class="container">
        <div class="navbar-brand tall-navbar-brand">
          <img
            src="./assets/cube-playmore-logo_hu12523d17ac9666eae9e5d9894f8e1095_13060_150x0_resize_q10_catmullrom_2.png"
            alt="Cube Playmore Logo"
          >
        </div>
      </div>
    </nav>

    <div class="main">
      <router-view/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
html {
    box-sizing: border-box;
    width: 100vw;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    scroll-padding-top: 100px;
}
*,
*:before,
*:after {
    box-sizing: inherit;
    padding: 0;
    margin: 0;
    position: relative;
    transition: all 0.3s ease;
}
hr {
    margin: 4em 0;
    border-top: 2px solid #fbb03c;
    border-bottom: none;
    border-left: 0;
    border-right: 0;
    border-radius: 30em;
    width: 100% !important;
}
.separator {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #b4dbe6;
    margin: 2em 0;
}
.main {
    padding-top: 45px;
}
@media only screen and (min-width: 64em) {
    .main {
        padding-top: 48px;
    }
}
@media only screen and (min-width: 80em) {
    .main {
        padding-top: 0;
    }
}
noscript {
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 100;
    background: #ef7123;
}
.wrapper,
.wrapper-fh {
    width: 100vw;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.wrapper-fh {
    min-height: 90vh;
}
.container,
.container-fw {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 10%);
}
.row-xs {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
}
.row {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.row-lg {
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-fw {
    width: 100vw !important;
}
@media only screen and (min-width: 48em) {
    .container,
    .container-fw {
        width: calc(100vw - 20%);
    }
}
@media only screen and (min-width: 64em) {
    .row {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
    }
}
@media only screen and (min-width: 80em) {
    .container,
    .container-fw {
        width: calc(100vw - 30%);
    }
}
@media only screen and (min-width: 100em) {
    .container,
    .container-fw {
        width: calc(100vw - 35%);
    }
    .row-lg {
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;
    }
}
form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
form input {
    width: 100%;
    padding: 1em 2em;
    margin: 0.5em 0 3em;
    border: 0;
    background-color: #f5f5f5;
}
form input:focus {
    outline: 1px solid #ef7123;
}
@media only screen and (min-width: 64em) {
    form {
        width: 80%;
    }
}
html {
    font-family: proxima-nova, sans-serif;
}
h1,
span.h1 p,
h2,
span.h2 p,
h3,
span.h3 p,
h4,
span.h4 p,
h5,
span.h5 p,
h6,
span.h6 p,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: open-sans, sans-serif;
    font-weight: 500;
    letter-spacing: 0.025em;
    margin-bottom: 1em;
    width: 100%;
    color: #404040;
}
h1,
span.h1 p,
.h1 {
    font-size: 1.8rem;
}
h2,
span.h2 p,
.h2 {
    font-size: 1.4rem;
}
h6,
span.h6 p,
.h6 {
    margin: 1em 0 2em;
}
p,
.p,
.lead,
span.lead p {
    line-height: 1.8em;
    letter-spacing: 0.025em;
    font-weight: 300;
    margin-bottom: 1.5em;
    width: 100%;
}
.lead,
span.lead p {
    font-weight: 300;
    font-size: 1.4rem !important;
}
.lead-small,
span.lead-small p {
    font-weight: 300;
    font-size: 1.2em !important;
}
span.text-center ul {
    width: max-content;
}
span.text-center ul li:not(.nav-item):before {
    content: "";
    display: none;
}
span.lead p {
    font-weight: 300;
}
span.m-0 * {
    margin: 0 !important;
}
ul,
ol {
    line-height: 1.8em;
    letter-spacing: 0.025em;
    font-weight: 300;
    list-style-position: outside;
    margin-bottom: 2em;
    width: 100%;
}
ul li,
ol li {
    margin-bottom: 0.5em;
}
ul {
    list-style-type: none;
}
ul li:not(.nav-item) {
    display: block;
    margin-left: 2rem;
}
ul li:not(.nav-item):before {
    content: "\f058";
    display: inline-block;
    position: absolute;
    left: -1.5rem;
    height: 100%;
    font-family: "font awesome\ 5 free";
    font-style: normal;
    font-size: 0.8em;
    font-weight: 900;
    color: #fbb03c;
}
@media only screen and (min-width: 48em) {
    ul li:not(.nav-item) {
        margin-left: 0;
    }
}
@media only screen and (min-width: 48em) {
    p,
    .p,
    .lead,
    span.lead p {
        text-align: justify;
        text-align-last: left;
    }
    p,
    .p,
    .lead,
    span.lead p,
    ul,
    ol {
        font-size: 1rem;
    }
}
@media only screen and (min-width: 64em) {
    h1,
    span.h1 p,
    .h1 {
        font-size: 3rem;
    }
    h2,
    span.h2 p,
    .h2 {
        font-size: 2.2rem;
    }
    h3,
    span.h3 p,
    .h3 {
        font-size: 2rem;
    }
    h4,
    span.h4 p,
    .h4 {
        font-size: 1.8rem;
    }
    h5,
    span.h5 p,
    .h5 {
        font-size: 1.4rem;
    }
    h6,
    span.h6 p,
    h6 {
        font-size: 1.2rem;
    }
}
a {
    color: #ef7123;
    text-decoration: none;
    font-weight: 600;
    letter-spacing: 0.025em;
    font-size: 1.2rem;
}
a:hover {
    color: #fbb03c;
}
@media only screen and (min-width: 90em) {
    p,
    .p,
    .lead,
    span.lead p,
    ul,
    ol {
        font-size: 1.1rem;
    }
    .lead,
    span.lead p {
        font-size: 2.2rem !important;
    }
    .lead-small,
    span.lead-small p {
        font-size: 1.6rem !important;
    }
}
@media only screen and (min-width: 100em) {
    p,
    .p,
    .lead,
    span.lead p,
    ul,
    ol {
        font-size: 1.2rem;
    }
}
.nav-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    position: absolute;
    width: 100%;
    top: 0;
    z-index: 100;
    padding: 0;
    box-shadow: 1px 1px 2px rgba(64, 64, 64, 0.2);
}
.mobile-topnav-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}
.navbar-brand img {
    width: 100px;
    padding: 0.5em 0;
    object-fit: contain;
}
a.navbrand-link {
    all: unset;
    height: 100%;
}
a.navbrand-link img {
    height: 100%;
    object-fit: contain;
}
a.navbrand-link:hover {
    cursor: pointer;
}
#navToggle {
    padding: 1em 0;
    color: #ef7123;
}
#mobileNav {
    display: none;
}
.nav-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
}
.nav-item {
    width: 100%;
    text-align: center;
    padding: 1.5em;
    margin-bottom: 0;
    font-family: open-sans, sans-serif;
    text-transform: uppercase;
    font-weight: 500;
}
.nav-item a {
    color: #404040;
    text-decoration: none;
    font-weight: 500;
}
.nav-item:hover {
    background-color: #ef7123;
}
.nav-item:hover a {
    color: #fff;
}
.nav-item.active {
    background-color: #fbb03c;
}
.nav-item.active a {
    color: #404040;
}
@media only screen and (min-width: 80em) {
    .mobile-topnav-wrapper {
        flex-direction: row;
        width: max-content;
        height: 70px;
    }
    #navToggle {
        display: none;
        font-size: 0;
    }
    #mobileNav {
        display: flex;
    }
    .nav-wrapper {
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -o-sticky;
        position: -ms-sticky;
        position: sticky;
        flex-direction: row;
        justify-content: space-around;
        height: 70px;
    }
    .nav-wrapper.sfp-nav .container,
    .nav-wrapper.sfp-nav .container-fw {
        flex-direction: row;
        justify-content: flex-start;
    }
    .navbar-brand {
        display: flex;
        width: 150px;
        height: 70px;
    }
    .navbar-brand img {
        width: 100%;
        padding: 0;
    }
    .navbar-brand.tall-navbar-brand {
        padding: 0.75em 0;
    }
    .nav-list {
        flex-direction: row;
        justify-content: flex-end;
        height: 100%;
        width: auto;
    }
    .nav-item {
        font-size: 0.9rem;
        padding: 0 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: max-content;
    }
    .nav-item a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        font-size: 0.9rem;
    }
}
button {
    border: 0;
}
.button,
.btn-large,
.btn-med,
button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    max-width: 80vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    border-radius: 30em;
    text-decoration: none;
    text-align: center;
    letter-spacing: 0.04em;
    background-color: #fff;
    border: 4px solid #ef7123;
    font-family: proxima-nova, sans-serif !important;
    font-weight: 700;
    color: #ef7123;
    -webkit-box-shadow: 0 2px 2px rgba(64, 64, 64, 0.4);
    box-shadow: 0 2px 2px rgba(64, 64, 64, 0.4);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}
.button:hover,
.btn-large:hover,
.btn-med:hover,
button:hover {
    cursor: pointer;
    background-color: #d0590f;
    color: #fff;
    border: 4px solid #fff;
    -webkit-box-shadow: 0 2px 2px rgba(64, 64, 64, 0.2);
    box-shadow: 0 2px 2px rgba(64, 64, 64, 0.2);
}
button {
    font-size: 1.2rem;
}
button:focus {
    outline: 1px solid #f5f5f5;
}
button:disabled {
  border: inherit;
}
button:hover:disabled {
  cursor: inherit;
  border: inherit;
  background-color: inherit;
  color: inherit;
}
a.button,
a.btn-large,
a.btn-med {
    font-size: 1.2rem;
}
.btn-large,
.btn-med {
    width: 20em;
    padding: 2em;
    margin: 2em 0;
    text-transform: none;
    font-size: 1.2em;
    text-align: left;
    min-height: 8rem;
}
.btn-large .fas,
.btn-med .fas {
    vertical-align: middle;
}
.btn-large::after,
.btn-med::after {
    content: "\f0a9";
    font-family: "font awesome\ 5 free";
    font-style: normal;
    font-weight: 900;
    padding-left: 0.5em;
}
.btn-large:hover,
.btn-med:hover {
    border: 4px solid #fff;
}
.btn-large:hover::after,
.btn-med:hover::after {
    transform: translateX(30%);
}
.btn-med {
    padding: 1em 0;
}
.close-modal:hover {
    cursor: pointer;
}
@media only screen and (min-width: 80em) {
    .btn-large,
    .btn-med {
        margin: 4em 0;
    }
}
@media only screen and (min-width: 100em) {
    .btn-large,
    .btn-med {
        width: 24em;
        min-height: auto;
    }
}
.img.hidden {
    display: none;
}
.img.shown {
    display: flex;
}
.img {
    width: 100%;
}
.img img {
    width: 100%;
}
.img-large {
    width: 100%;
    height: 100%;
    margin: 3em 0 6em;
}
.img-large img {
    width: 100%;
    height: 100%;
}
.status {
    background-color: #f5f5f5;
    width: 100%;
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 2em;
}
span#connectionStatus,
span#upToDateStatus {
    font-size: 8em;
    color: #fbb03c;
    color: #d00000;
    color: #404040;
    font-weight: 700;
    margin: 0;
    box-sizing: content-box;
}
@media only screen and (min-width: 64em) {
    .status {
        width: 33%;
        margin: 0;
        justify-content: flex-start;
    }
}
#installationGuideButton {
    margin-bottom: 2em;
}
#installationGuideButton::after {
    content: "\f085";
    font-family: "font awesome\ 5 free";
    font-style: normal;
    font-weight: 900;
    padding-left: 0.5em;
}
#installationGuideButton:hover {
    border: 4px solid #fff;
}
#installationGuideButton:hover::after {
    transform: translateX(30%);
}
#webuiButton {
    margin-bottom: 2em;
}
#webuiButton::after {
    content: "\f144";
    font-family: "font awesome\ 5 free";
    font-style: normal;
    font-weight: 900;
    padding-left: 0.5em;
}
#webuiButton:hover {
    border: 4px solid #fff;
}
#webuiButton:hover::after {
    transform: translateX(30%);
}
@media only screen and (min-width: 64em) {
    #installationGuideButton {
        margin: 0 4em 0 0;
    }
    #webuiButton {
        margin: 0 0 0 4em;
    }
}
.control-btn {
    height: 5em;
    width: 16em;
}
#webuiInfo {
    display: flex;
    align-items: center;
    justify-content: center;
}
#webuiInfo::before {
    content: "\f05a";
    font-family: "font awesome\ 5 free";
    font-style: normal;
    font-weight: 900;
    padding-right: 0.6em;
}
.m-0 {
    margin: 0 !important;
}
.mb-0 {
    margin-bottom: 0;
}
.mt-2 {
    margin-top: 2em;
}
.mb-2 {
    margin-bottom: 2em;
}
.my-2 {
    margin-top: 2em;
    margin-bottom: 2em;
}
.mr-2 {
    margin-right: 2em;
}
.ml-2 {
    margin-left: 2em;
}
.mx-2 {
    margin-right: 2em;
    margin-left: 2em;
}
.mt-4 {
    margin-top: 4em;
}
.mb-4 {
    margin-bottom: 4em;
}
.my-4 {
    margin-top: 4em;
    margin-bottom: 4em;
}
.my-sm-2 {
    margin-top: 2em;
    margin-bottom: 2em;
}
@media only screen and (min-width: 64em) {
    .my-sm-2 {
        margin: unset;
    }
}
@media only screen and (min-width: 64em) {
    .my-md-4 {
        margin-top: 4em;
        margin-bottom: 4em;
    }
}
.mr-4 {
    margin-right: 4em;
}
.ml-4 {
    margin-left: 4em;
}
.mx-4 {
    margin-right: 4em;
    margin-left: 4em;
}
.mt-8 {
    margin-top: 8em;
}
.mb-8 {
    margin-bottom: 8em;
}
.my-8 {
    margin-top: 4em;
    margin-bottom: 4em;
}
@media only screen and (min-width: 48em) {
    .my-8 {
        margin-top: 8em;
        margin-bottom: 8em;
    }
}
.py-1rem {
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.py-1 {
    padding-top: 1em;
    padding-bottom: 1em;
}
.p-2 {
    padding: 2em;
}
.pt-2 {
    padding-top: 2em;
}
.pb-2 {
    padding-bottom: 2em;
}
.py-2 {
    padding-top: 2em;
    padding-bottom: 2em;
}
.pr-2 {
    padding-right: 2em;
}
.pl-2 {
    padding-left: 2em;
}
.px-2 {
    padding-right: 2em;
    padding-left: 2em;
}
.pt-4 {
    padding-top: 4em;
}
.pb-4 {
    padding-bottom: 4em;
}
.py-4 {
    padding-top: 4em;
    padding-bottom: 4em;
}
.pr-4 {
    padding-right: 4em;
}
.pl-4 {
    padding-left: 4em;
}
.px-4 {
    padding-right: 4em;
    padding-left: 4em;
}
.p-4 {
    padding: 2em;
}
@media only screen and (min-width: 48em) {
    .p-4 {
        padding: 4em;
    }
}
@media only screen and (min-width: 100em) {
    .p-4 {
        padding: 8em;
    }
}
.pt-8 {
    padding-top: 4em;
}
.pb-8 {
    padding-bottom: 4em;
}
.py-8 {
    padding-top: 4em;
    padding-bottom: 4em;
}
@media only screen and (min-width: 48em) {
    .pt-8 {
        padding-top: 8em;
    }
    .pb-8 {
        padding-bottom: 8em;
    }
    .py-8 {
        padding-top: 8em;
        padding-bottom: 8em;
    }
}
.pr-8 {
    padding-right: 8em;
}
.pl-8 {
    padding-left: 8em;
}
.px-8 {
    padding-right: 8em;
    padding-left: 8em;
}
.flex-align-center {
    align-items: center;
}
.flex-reverse {
    flex-direction: row-reverse;
}
.flex-justify-space-around {
    justify-content: space-around;
}
.flex-justify-center {
    justify-content: center;
}
.text-center {
    text-align: center !important;
    text-align-last: center !important;
}
.text-center * {
    text-align: center !important;
    text-align-last: center !important;
}
.text-left {
    text-align: left !important;
    text-align-last: left !important;
}
.text-left * {
    text-align: left !important;
    text-align-last: left !important;
}
.text-fullwidth {
    width: 100%;
}
.text-white {
    color: #fff !important;
}
.text-light-grey {
    color: #8f8f8f !important;
}
.text-bold {
    font-weight: 600 !important;
}
.background-primary {
    background: #ef7123;
}
.background-primary * {
    color: #fff !important;
}
.background-primary a.button {
    color: #ef7123 !important;
}
.background-primary a.button:hover {
    color: #fff !important;
}
.background-secondary {
    background: #fbb03c;
}
.background-secondary * {
    color: #000 !important;
}
.background-secondary a.button {
    color: #ef7123 !important;
}
.background-secondary a.button:hover {
    color: #fff !important;
}
.background-light-grey {
    background: #f5f5f5;
}
.background-light-grey * {
    color: #000 !important;
}
.background-light-grey a.button {
    color: #ef7123 !important;
}
.background-light-grey a.button:hover {
    color: #fff !important;
}
.background-light-grey a {
    color: #ef7123 !important;
}
.background-dark-grey {
    background: #404040;
}
.background-dark-grey * {
    color: #fff !important;
}
.background-light-orange-tech-splash {
    background-color: #fff;
}
.background-light-grey-tech-splash {
    background-color: #fff;
}
@media only screen and (min-width: 80em) {
    .background-light-orange-tech-splash {
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }
    .background-light-grey-tech-splash {
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        background-attachment: scroll;
    }
}
.bb-primary {
    border-bottom: 2px solid #ef7123;
    width: 100%;
}
</style>
