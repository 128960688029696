<template>
  <div
    class="container py-4"
    id="cubeControl"
  >
    <h1 class="text-center">my CUBE</h1>

    <div class="row my-sm-2 my-md-4 flex-justify-center">
      <a
        class="button control-btn"
        href="https://support.cubemc.com/portal/en/kb/articles/cube-installation-guide"
        id="installationGuideButton"
      >Install My CUBE</a>
      <button
        class="button control-btn"
        id="webuiButton"
        :disabled="!hasMusicControl"
        @click="goMusicControl"
      >Manage My Music</button>
    </div>
    <p
      id="webuiInfo"
      class="text-center text-light-grey mb-2"
    >
      {{ infotext }}
    </p>
    <div class="row">

      <div class="status">
        <h2 class="h5 text-center py-1rem">
          my CUBE
        </h2>
        <p class="h6 text-center m-0 text-bold">
          Serial Number
        </p>
        <p
          class="text-center"
          id="serialNumber"
        >
          {{ this.serialNumber }}
        </p>
        <p class="h6 text-center m-0 text-bold">
          Mac Address
        </p>
        <p
          class="text-center"
          id="macAddress"
        >
          {{ this.macAddress }}
        </p>
      </div>

      <div class="status">
        <h2
          class="h5 text-center py-1rem"
          id="connectionInfo"
        >
          CUBE is <strong>{{ isOnline ? "online" : "offline" }}</strong>
        </h2>
        <span
          class="text-center"
          id="connectionStatus"
        >
          {{ isOnline ? successSymbol : failSymbol }}
        </span>
      </div>

      <div class="status">
        <h2
          class="h5 text-center py-1rem"
          id="upToDateInfo"
        >
          CUBE is {{ isUpToDate ? "" : "not" }} up to date
        </h2>
        <span
          class="text-center"
          id="upToDateStatus"
        >
          {{ isUpToDate ? successSymbol : failSymbol }}
        </span>
      </div>
    </div>
    <div class="my-4">
      <a
        href="https://support.cubemc.com/portal/en/kb/articles/cube-installation-guide"
        target="_blank"
      >View the CUBE Installation Guide</a>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'Unit',
  props: ['unitinfo', 'serialNumber', 'macAddress'],
  data () {
    return {
      successSymbol: "\u2713",   // tickmark
      failSymbol: "\u0021",      // exlamation
      webui_links: {}            // we will store the available links here
    }
  },
  computed: {
    isOnline () {
      return (
        ("network" in this.unitinfo) && this.unitinfo.network.isOnline
      )
    },
    isUpToDate () {
      return (
        ("entitlement" in this.unitinfo) &&
        ("firmware" in this.unitinfo) &&
        ("manifeststatus" in this.unitinfo) &&
        (this.unitinfo.manifeststatus.state === "SUCCESS")
      )
    },
    hasMusicControl () {
      return (this.musicLink != '#')
    },
    musicLink () {
      if ('bonjour' in this.webui_links) return this.webui_links.bonjour
      if ('direct' in this.webui_links) return this.webui_links.direct
      if ('remote' in this.webui_links) return this.webui_links.remote
      return "#"
    },
    infotext () {
      if (!this.unitinfo.entitlement.entitlement.includes("LM")) {
        return "This CUBE does not have a music service yet."
      }
      if (
        ('bonjour' in this.webui_links) ||
        ('direct' in this.webui_links)
      ) return "This music control panel is available on the local network."
      if ('remote' in this.webui_links) {
        return "This music control panel is available remotely."
      }
      return "This music control panel is not available on the local network, nor is remote access enabled." // Contact us to enable access.
    }
  },
  methods: {
    goMusicControl () {
      window.location = this.musicLink
    },
    async detectUnit (type, domain) {
      try {
        const t = Date.now()
        const response = await this.axios.get(`http://${domain}/cgi-bin/check?_=${t}`)
        // console.log(type, response)
        if (response.data.success) {
          Vue.set(this.webui_links, type, `http://${domain}/`)
        }
      } catch (e) {
        // ?
      }
    },
  },
  async mounted () { 
    if (
      ('network' in this.unitinfo) &&
      ('remotePort' in this.unitinfo.network) &&
      (this.unitinfo.network.remotePort != "")
    ) {
      Vue.set(this.webui_links, 'remote', `https://remote.cubemc.net/remoteui/${this.unitinfo.network.remotePort}`)
    }
    this.detectUnit('bonjour', `ni1${this.macAddress}.local`)
    this.detectUnit('direct', `${this.unitinfo.network.internalIP}`)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
